import { useTranslations } from 'next-intl'
import type { FC } from 'react'

import { ButtonPill, IconDownload } from '@fortum/elemental-ui'

import { downloadCSV } from '@/logged-in/utils/commonUtils'
import { useFullLocale, useLocale } from '@/shared/locale'
import { sendGTMEvent } from '@/shared/utils/sendGTMEvent'

import { generateSpotPriceCSV } from '../actions'
import type { SpotEntry } from '../commons'
import { useStore } from '../StoreProvider'

export type DownloadButtonProps = {
  data: SpotEntry[]
}

export const DownloadButton: FC<DownloadButtonProps> = ({ data }) => {
  const t = useTranslations('spotPrices')
  const averagePrice = useStore((s) => s.averagePrice)
  const activeDate = useStore((s) => s.activeDate)
  const fullLocale = useFullLocale()
  const locale = useLocale()
  const priceArea = useStore((s) => s.priceArea)

  return (
    <ButtonPill
      data-testid="download-button"
      status="secondary"
      variant="condensed"
      leftIcon={IconDownload}
      alignSelf={{ default: 'stretch', s: 'flex-end' }}
      height="40px"
      onClick={async () => {
        if (data.length === 0) {
          return null
        }
        const csvString = await generateSpotPriceCSV(data, averagePrice, fullLocale, priceArea)

        downloadCSV('spotprice', csvString, activeDate, priceArea, locale)
        sendGTMEvent({
          event: 'graph_interaction',
          graph_title: 'Spot price',
          click_element: 'download_button',
          click_text: t('download'),
        })
      }}
    >
      {t('download')}
    </ButtonPill>
  )
}
