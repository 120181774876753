import { rejectNil } from '@/shared/utils/array'

export const concatText = (...items: Array<string | Record<string, boolean | undefined>>) => {
  return items
    .map((item) => {
      if (typeof item === 'string') {
        return item
      }
      return rejectNil(Object.entries(item).flatMap(([key, value]) => (value ? key : undefined)))
    })
    .join(' ')
}
